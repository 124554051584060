import React, { Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";


type PrivateRouteProps = { isAuthenticated: boolean };

const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAuthenticated }) => {
  return isAuthenticated ? (
    <Suspense fallback={null}>
        <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/auth/signin" />
  );
};

export default React.memo(PrivateRoute);
