import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useGeneralEventDispatcher } from "../eventDispatcher/event-dispatcher-context"
import {
  CoreEvents,
  expandSidebar,
  setUserLogin,
} from "../eventDispatcher/eventos/core_events"
import { CORE_EVENTS, UserLogin } from "../eventDispatcher/eventos/types"
import { EventDispatcher } from "bionline-library-utilities"
import { DependencyContainer } from "tsyringe"

export type AirNostrumContextType = {
  user: UserLogin | undefined
  isSideBarExpanded: boolean | undefined
  generalEventDispatcher: EventDispatcher<CoreEvents>
}

export interface AirNostrumContextProps {
  children: React.ReactNode
}

const AirNostrumContext = createContext<AirNostrumContextType | undefined>(
  undefined
)

export function AirNostrumProvider({ children }: AirNostrumContextProps) {
  const generalEventDispatcher = useGeneralEventDispatcher()

  const [user, setUser] = useState<UserLogin | undefined>(() => {
    return (
      (window.sessionStorage.getItem("user") !== undefined &&
        JSON.parse(window.sessionStorage.getItem("user") as string)) ||
      undefined
    )
  })
  const [isSideBarExpanded, toggleSideBar] = useState<boolean | undefined>(
    false
  )

  useEffect(() => {
    if (user) {
      let new_user = JSON.stringify(user)
      window.sessionStorage.setItem("user", new_user)
    } else {
      window.sessionStorage.removeItem("user")
    }

    return () => {
      window.sessionStorage.clear()
    }
  }, [user])

  const callbackSetUser = useCallback(
    (evt: setUserLogin<typeof CORE_EVENTS.SET_USER>) => {
      setUser(evt.user)
    },
    []
  )

  const callbackExpandSidebar = useCallback(
    (evt: expandSidebar<typeof CORE_EVENTS.EXPAND_SIDEBAR>) => {
      toggleSideBar(evt.expand)
    },
    []
  )

  useEffect(() => {
    generalEventDispatcher.subscribe(
      CORE_EVENTS.EXPAND_SIDEBAR,
      callbackExpandSidebar
    )
    generalEventDispatcher.subscribe(CORE_EVENTS.SET_USER, callbackSetUser)
    return function () {
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.EXPAND_SIDEBAR,
        callbackExpandSidebar
      )
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.SET_USER,
        callbackSetUser
      )
    }
  }, [callbackExpandSidebar, callbackSetUser, generalEventDispatcher])

  let estado: AirNostrumContextType = {
    user,
    generalEventDispatcher,
    isSideBarExpanded,
  }

  return (
    <AirNostrumContext.Provider value={estado}>
      {children}
    </AirNostrumContext.Provider>
  )
}

export function useAirNostrumState() {
  const context = useContext(AirNostrumContext)
  if (context === undefined) {
    throw new Error("This state must be used within a Provider")
  }
  return context
}
