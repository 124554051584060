export const getBrowserLanguage = () => {
  let userLang = navigator.language;
  if (userLang.indexOf("es") !== -1) {
    return LOCALES.SPANISH;
  } else if (userLang.indexOf("en") !== -1) {
    return LOCALES.ENGLISH;
  } else {
    return LOCALES.SPANISH;
  }
};

export const LOCALES = {
  SPANISH: "es-ES",
  ENGLISH: "en-US",
};

export const getApiLanguage = (idLanguage: number): string => {
  if (idLanguage === 1) {
    return LOCALES.SPANISH;
  } else if (idLanguage === 2) {
    return LOCALES.ENGLISH;
  } else {
    return LOCALES.SPANISH;
  }
};

export const setApiLanguage = (language: string): number => {
  if (language === "es-ES") {
    return 1;
  } else if (language === "en-US") {
    return 2;
  }
  return 2;
};
