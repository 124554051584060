import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAirNostrumState } from "../../../../context/airnostrum-context"
import { CORE_EVENTS } from "../../../../eventDispatcher/eventos/types"
import { TOAST_STATUS } from "bionline-components-library"
import { CoreEvents } from "../../../../eventDispatcher/eventos/core_events"
import { EventDispatcher } from "bionline-library-utilities"

interface LogoutProps {
  generalEventDispatcher: EventDispatcher<CoreEvents>
}

const Logout = ({ generalEventDispatcher }: LogoutProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    generalEventDispatcher.dispatch(CORE_EVENTS.SET_USER, {
      type: CORE_EVENTS.SET_USER,
      user: undefined,
      timestamp: new Date(),
      lang: "es-ES",
    })
    window.sessionStorage.clear()
    navigate("/auth/signin")
  }, [])

  return <>Saliendo...será rederigido en unos segundos</>
}

export default Logout
