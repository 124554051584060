import { BrowserRouter as Router } from "react-router-dom"
import "reflect-metadata"
import "./App.css"
import AppRouter from "./Routes/AppRouter"
import { AirNostrumProvider } from "./context/airnostrum-context"
import { GeneralEventDispatcherContextProvider } from "./eventDispatcher/event-dispatcher-context"

function App() {
  
  return (
    <div className="App">
      <GeneralEventDispatcherContextProvider>
        <AirNostrumProvider >
          <Router>
            <AppRouter />
          </Router>
        </AirNostrumProvider>
      </GeneralEventDispatcherContextProvider>
    </div>
  )
}

export default App
