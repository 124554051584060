import React, { useMemo, useCallback, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Sidebar as SidebarComponent } from "bionline-components-library"
import diccionario from "../../../i18n/Common.translations/SideBar"
import { useAirNostrumState } from "../../../../context/airnostrum-context"
import {
  FiBell,
  FiDatabase,
  FiFile,
  FiLogOut,
  FiSettings,
  FiUser,
  FiUsers,
} from "react-icons/fi"
import { FaPlane } from "react-icons/fa"
import { CORE_EVENTS } from "../../../../eventDispatcher/eventos/types"

const Sidebar = () => {
  const path = useLocation()
  const navigate = useNavigate()
  const { isSideBarExpanded, user, generalEventDispatcher } =
    useAirNostrumState()

  const [estadoSidebar, setEstadoSidebar] = useState<boolean>(
    isSideBarExpanded ?? false
  )

  useEffect(() => {
    if (estadoSidebar !== isSideBarExpanded) {
      generalEventDispatcher.dispatch(CORE_EVENTS.EXPAND_SIDEBAR, {
        type: CORE_EVENTS.EXPAND_SIDEBAR,
        expand: estadoSidebar,
        timestamp: new Date(),
        lang: "es-ES",
      })
    }
  }, [estadoSidebar])

  const { Usuarios, Perfil, CerrarSesion } = diccionario["es-ES"]
  const handleLogOut = useCallback(() => {
    navigate("/auth/logout")
  }, [navigate])

  const handleNavigation = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )

  const navItems = useMemo(() => {
    let nav = {
      main: [
        {
          title: "Vuelos",
          icon: FaPlane,
          path: "/flights/home",
          action: () => {
            handleNavigation("/flights/home")
          },
        },
        {
          title: "Ficheros",
          icon: FiFile,
          path: "/bihub/home",
          action: () => {
            handleNavigation("/bihub/home")
          },
        },
        {
          title: "BiData",
          icon: FiDatabase,
          path: "/bidata/home",
          action: () => {
            handleNavigation("/bidata/home")
          },
        },
      ],
      footer: [
        {
          title: "Ajustes",
          icon: FiSettings,
          path: "/auth",
          children: [
            {
              title: "Notificaciones",
              icon: FiBell,
              action: () => {
                handleNavigation("/events/home")
              },
            },
            {
              title: Perfil,
              icon: FiUser,
              action: () => {
                handleNavigation("/auth/profile")
              },
            },
          ],
        },
        {
          title: CerrarSesion,
          icon: FiLogOut,
          action: () => {
            handleLogOut()
          },
          logout: true,
        },
      ],
    }

    const users = {
      title: Usuarios,
      icon: FiUsers,
      action: () => {
        handleNavigation("/auth/users")
      },
    }

    if (user?.role === "Admin") {
      nav.footer[0]?.children?.push(users)
    }

    return nav
  }, [handleLogOut, handleNavigation, CerrarSesion, Perfil, Usuarios])

  return (
    <>
      {
        <SidebarComponent
          expanded={estadoSidebar as boolean}
          setExpanded={setEstadoSidebar}
          items={navItems.main}
          footerItems={navItems.footer}
          logo="/Logo.png"
          logoExpanded="/Logo-expanded.png"
          path={path.pathname}
        />
      }
    </>
  )
}

export default React.memo(Sidebar)
