import React from "react";
import { IRutasPrivadas } from "../core/types/common-types";

// Auth routes
const Usuarios = React.lazy(() => import("../Pages/Auth.pages/Users"));
const Perfil = React.lazy(() => import("../Pages/Auth.pages/Profile"));

//BiData routes
const BiDataHome = React.lazy(() => import("../Pages/BiData.pages"));

// Audiences pages
const AirNostrumHome = React.lazy(() => import("../Pages/AirNostrum.pages/Home"));

// Bihub pages
const BihubHome = React.lazy(() => import("../Pages/Bihub.pages"));

// Eventos pages
const EventsHome = React.lazy(() => import("../Pages/Eventos.pages"));

export const RutasPrivadas: IRutasPrivadas[] = [
    {
        id: 0,
        path: "/auth/users",
        component: Usuarios,
        titulo: "Usuarios",
      },
      {
        id: 1,
        path: "/auth/profile",
        component: Perfil,
        titulo: "Perfil",
      },
      {
        id: 10,
        path: "/bihub/home",
        component: BihubHome,
        titulo: "Bihub",
      },
      {
        id: 20,
        path: "/events/home",
        component: EventsHome,
        titulo: "Eventos",
      },
      {
        id: 30,
        path: "/flights/*",
        component: AirNostrumHome,
        titulo: "Audiences",
      },
      {
        id: 40,
        path: "/bidata/home",
        component: BiDataHome,
        titulo: "BiData",
      },
];
