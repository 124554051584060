import { ToastContextProvider } from "bionline-components-library"
import { ApiLanguage, Helpers, Languages } from "bionline-library-utilities"
import { LoginHome } from "bionline-modules"
import { useCallback, useEffect } from "react"
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom"
import { useAirNostrumState } from "../context/airnostrum-context"
import { ErrorBoundary } from "../core/interceptors/ErrorBoundary"
import { IRutasPrivadas } from "../core/types/common-types"
import Layout from "../core/ui/components/Layout"
import Logout from "../core/ui/components/Logout"
import { NotFound } from "../core/ui/components/NotFound"
import {
  GeneralEventDispatcherContextProvider,
  useGeneralEventDispatcher,
} from "../eventDispatcher/event-dispatcher-context"
import {
  CoreEvents,
  MensajeGenerico,
  Navigate as NavigateEvt,
} from "../eventDispatcher/eventos/core_events"
import { CORE_EVENTS } from "../eventDispatcher/eventos/types"
import PrivateRoute from "./PrivateRoute"
import { RutasPrivadas } from "./RutasPrivadas"
import { UIScreen } from "../core/ui/components/UIScreen"

type ToastDispatcher<T> = keyof T

const { LANGUAGES } = Languages

const AppRouter = () => {
  const generalEventDispatcher = useGeneralEventDispatcher()

  const { user } = useAirNostrumState()

  const navigate = useNavigate()

  const callbackNavigate = useCallback(
    (evt: NavigateEvt<typeof CORE_EVENTS.NAVIGATE>) => {
      navigate(evt.url)
    },
    [navigate]
  )

  const callbackMensajeGenerico = useCallback(
    (evt: MensajeGenerico<typeof CORE_EVENTS.MENSAJE_GENERICO>) => {},
    []
  )

  useEffect(() => {
    generalEventDispatcher.subscribe(CORE_EVENTS.NAVIGATE, callbackNavigate)
    generalEventDispatcher.subscribe(
      CORE_EVENTS.MENSAJE_GENERICO,
      callbackMensajeGenerico
    )
    return () => {
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.NAVIGATE,
        callbackNavigate
      )
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.MENSAJE_GENERICO,
        callbackMensajeGenerico
      )
    }
  }, [callbackMensajeGenerico, callbackNavigate, generalEventDispatcher])

  const TOAST_EVENTS: ToastDispatcher<CoreEvents>[] = [
    CORE_EVENTS.MENSAJE_GENERICO,
  ]

  const language = Helpers.getApiLanguage(user?.idioma as ApiLanguage)

  return (
    <GeneralEventDispatcherContextProvider>
      <UIScreen lang={language} eventDispatcher={generalEventDispatcher}>
        <ToastContextProvider<CoreEvents>
          events={TOAST_EVENTS}
          eventDispatcher={generalEventDispatcher}
        >
          <Layout isLoggedIn={user !== undefined}>
            <ErrorBoundary>
              <Routes>
                <Route
                  path="/auth/signin"
                  element={
                    user === undefined ? (
                      <LoginHome
                        generalEventDispatcher={generalEventDispatcher}
                      />
                    ) : (
                      <Navigate to="/flights/home" />
                    )
                  }
                />
                <Route
                  path="/auth/logout"
                  element={
                    <Logout generalEventDispatcher={generalEventDispatcher} />
                  }
                />
                <Route
                  element={
                    <PrivateRoute isAuthenticated={user !== undefined} />
                  }
                >
                  {RutasPrivadas.map(
                    ({ component: Component, path, id }: IRutasPrivadas) => {
                      return (
                        <Route key={id} path={path} element={<Component />} />
                      )
                    }
                  )}
                </Route>
                <Route path="/" element={<Navigate to="/auth/signin" />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ErrorBoundary>
          </Layout>
        </ToastContextProvider>
      </UIScreen>
    </GeneralEventDispatcherContextProvider>
  )
}

export default AppRouter
