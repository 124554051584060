import SideBar from "../Sidebar"
import { motion, AnimatePresence } from "framer-motion"
import { Container } from "bionline-components-library"

import "./Layout.css"
import { AirNostrumContainer } from "./layout.styles"

interface ILayout {
  children: JSX.Element
  isLoggedIn: boolean
}

const Layout = ({ children, isLoggedIn }: ILayout) => {
  return (
    <>
      <div className={isLoggedIn ? "container-shell" : ""}>
        {isLoggedIn && <SideBar />}

        <div className={isLoggedIn ? "container-shell-children" : ""}>
          {isLoggedIn ? <Container>{children}</Container> : children}
        </div>
      </div>
    </>
  )
}

export default Layout
