import { LOCALES } from "../../constants";
import literales_en from "./en-US";
import literales_es from "./es-ES";

const diccionario = {
  [LOCALES.ENGLISH]: literales_en,
  [LOCALES.SPANISH]: literales_es,
};

export default diccionario;
