type MarginType = {
  padding?: string
  marginCrossIcon?: string
  heightWrapper?: string
}

export type ComponentPanel = {
  component: React.ReactElement
  title: string
  width?: number
  estilo?: MarginType
  callbackClose?: Function
  hideCancelButton?: boolean
  cancelFn?: ({ ...args }: any) => void
  paddingBtnCancel?: number
  clickOutside?: boolean
}

export const SCREEN_OPTIONS = {
  MODIFICAR_ACCION_COMERCIAL: "Modificar acción comercial",
  FILTROS_ACCION_COMERCIAL_HOME: "Filtros acción comercial home",
  SELECCIONAR_CAMPANYA: "Seleccionar campaña",
  CREATE_CAMPANYA: "Crear campaña",
  INFO_CASO_USO: "Info caso uso",
  EVENTS_PANEL: "panel lateral eventos",
  CONDICIONES_MANAGER: "Condiciones manager",
  ADD_CONDICIONES_CONVERSION: "Añadir condiciones conversion",
  SELECT_BIDATA_ENTITY: "Seleccionar entidad biData",
  FILTER_TIPOS_ACCION: "Filtros tipo accion",
  SHOW_EVENTS_PANEL_LATERAL: "panel lateral eventos",
  SHOW_NEW_USER_PANEL_LATERAL: "panel lateral usuarios",
  SHOW_PROFILE_PANEL_LATERAL: "panel lateral profile"
} as const

export type ScreenOptionsType =
  (typeof SCREEN_OPTIONS)[keyof typeof SCREEN_OPTIONS]

export const CORE_EVENTS = {
  MENSAJE_GENERICO: "MENSAJE_GENERICO",
  NAVIGATE: "NAVIGATE",
  SHOW_PANEL_CORE: "SHOW_PANEL_CORE",
  REQUEST_COMPONENT_FROM_SCREEN_OPTION: "REQUEST_COMPONENT_FROM_SCREEN_OPTION",
  SET_USER: "SET USER",
  EXPAND_SIDEBAR: "Expand sidebar"
} as const

export const EVENT_STATUS_TYPES = {
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  INFO: "INFO",
  WARNING: "WARNING"
} as const

export type Token = string

export interface UserLogin {
  alias?: string
  appArranque?: string
  apps: string[]
  email: string
  idCustomer: string
  id: string
  idioma: number
  inBioUser: number
  jwToken: Token
  persistorKey?: Token
  role: string
  userName: string
  dominioCustomer?: string
  codSector?: string
}

export type StatusType =
  (typeof EVENT_STATUS_TYPES)[keyof typeof EVENT_STATUS_TYPES]
